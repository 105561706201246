































































import { ApiCloseCourseValidationCategoryDto } from "@/api/generated/Api";
import { getErrorCategoryColor, getErrorCategoryIcon } from "@/shared/helpers/endCourseHelper";
import { EndCourseValidationStatus } from "@/shared/constants/endCourseValidationStatus";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    category: {
      type: Object as PropType<ApiCloseCourseValidationCategoryDto | null>,
      required: false,
      default: null,
    },
    btnText: {
      type: String,
      required: false,
      default: "",
    },
    successText: {
      type: String,
      required: false,
    },
    isUserButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  name: "EndCourseExpansionPanel",
  setup(props, { emit }) {
    const onListItemClick = (userId: number) => {
      emit("onListItemClick", userId);
    };

    const courseValidationStatus = computed(() => {
      if (props.category?.numberOfErrors) {
        return EndCourseValidationStatus.Error;
      }

      if (props.category?.numberOfWarnings) {
        return EndCourseValidationStatus.Warning;
      }

      return EndCourseValidationStatus.Success;
    });

    const hasWarningOrError = computed(() => {
      if (props.category?.numberOfErrors || props.category?.numberOfWarnings) {
        return true;
      }
      return false;
    });

    return {
      getErrorCategoryColor,
      getErrorCategoryIcon,
      onListItemClick,
      errors: props.category?.errors ?? [],
      warnings: props.category?.warnings ?? [],
      hasWarningOrError,
      courseValidationStatus,
    };
  },
});
